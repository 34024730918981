import { formatWholeNumber } from '@kijiji/number/formatWholeNumber'
import { isPositiveInteger } from '@kijiji/number/isPositiveInteger'
import _padEnd from 'lodash/padEnd'

const CENTS = 1
const DOLLARS: number = CENTS * 100

/**
 * Parses a string representing a price into an integer representing the
 * appropriate number of cents.
 *
 * For example "17.5" ($17.50) would become 1750.
 *
 * @param    string    price    The price to parse.
 *
 * @return   integer            The corresponding number of cents.
 */
export const parsePrice = (price: string) => {
  if (typeof price !== 'string') {
    return null
  }

  const PRICE = /^(\d+)\.?(\d+?)?$/
  const priceParts = price.trim().match(PRICE)

  if (priceParts == null) {
    return null
  }

  const dollars = priceParts[1]
  const cents = priceParts[2]

  const stringCents = cents || ''

  const normalizedCents = _padEnd(stringCents.substring(0, 2), 2, '0')

  return parseInt(dollars + normalizedCents, 10)
}

/**
 * Converts an integer monetary value (denominated in cents)
 * into structured data representing the price's components.
 * Pads cent values with an extra zero when less than 10.
 *
 * @param    integer    cents    The price in cents.
 *
 * @return   object              The price's components:
 *                                 - price.dollars (integer)
 *                                 - price.cents (integer)
 */
export const priceFromCents = (cents: number | string) => {
  const splitDollars = Math.floor(parseInt(cents as string) / DOLLARS)
  const splitCents = parseInt(cents as string) % DOLLARS

  return {
    dollars: splitDollars.toString(),
    cents: (splitCents < 10 ? '0' : '') + splitCents.toString(),
  }
}

/**
 * Format a number as currency, localized
 *
 * @param   integer    value    The price in cents.
 * @param   object     config   config.isFrench - render price localized in French
 *                              config.suppressCents - hide cents and round up to nearest dollar
 *
 * @return  string
 */
export const formatPrice = (
  value: number,
  config: { isFrench?: boolean; suppressCents?: boolean } = {
    isFrench: false,
    suppressCents: false,
  }
) => {
  const { isFrench, suppressCents } = config

  if (!isPositiveInteger(value)) {
    return null
  }

  const price = priceFromCents(value)

  const { dollars, cents } = price
  let dollarsOnly = 0
  if (suppressCents) {
    dollarsOnly = Math.ceil(value / DOLLARS)
  }

  let localizedPrice

  if (isFrench) {
    localizedPrice = suppressCents
      ? `${formatWholeNumber(dollarsOnly, false)} $`
      : `${formatWholeNumber(Number.parseInt(dollars, 10), false)},${cents} $`
  } else {
    localizedPrice = suppressCents
      ? `$${formatWholeNumber(dollarsOnly, true)}`
      : `$${formatWholeNumber(Number.parseInt(dollars, 10), true)}.${cents}`
  }
  return localizedPrice
}

import { type AmountPrice } from '@kijiji/generated/graphql-types'

import { type VIP_Listing } from '@/types/listings'

export const isAmountPrice = (listingPrice: VIP_Listing['price']): listingPrice is AmountPrice => {
  if (
    listingPrice &&
    (listingPrice?.__typename === 'AutosDealerAmountPrice' ||
      listingPrice?.__typename === 'StandardAmountPrice' ||
      'amount' in listingPrice)
  ) {
    return true
  }

  return false
}
